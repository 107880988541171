import * as dates from './dates'
import events from './events'
import './style.scss'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useMemo } from 'react'
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar'

const mLocalizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */
export default function Basic({
  localizer = mLocalizer,
  showDemoLink = true,
  ...props
}) {
  const { components, defaultDate, max, views } = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
        eventWrapper: (eventWrapperProps) => {
          return (
            <div className={classNames(eventWrapperProps.event)}>
              {eventWrapperProps.children}
            </div>
          )
        },
      },
      defaultDate: new Date(2015, 3, 1),
      max: dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      views: Object.keys(Views).map((k) => Views[k]),
    }),
    []
  )

  return (
    <Fragment>
      <div className='calendar'>
        <div className='height600' {...props}>
          <Calendar
            components={components}
            defaultDate={defaultDate}
            events={events}
            localizer={localizer}
            max={max}
            showMultiDayTimes
            step={60}
            popup
            views={{
              month: true,
              day: false,
              week: false,
              work_week: false,
            }}
          />
        </div>
      </div>
    </Fragment>
  )
}
Basic.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}
