const now = new Date()

export default [
  /* {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  }, */
  {
    id: 1,
    title: '$294 Gotti, Capone',
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
    allDay: true,
  },

  {
    id: 2,
    title: 'DTS STARTS',
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: 'DTS ENDS',
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 92,
    title: '$82 Farrah',
    start: new Date(2015, 3, 9, 8, 0, 0),
    end: new Date(2015, 3, 10, 11, 30, 0),
  },
  {
    id: 12,
    title: '$55 Gary',
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    id: 9888,
    title: '$60 Lady, Sasha',
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 19, 30, 0),
  },
  {
    id: 13.1,
    title: 'Unavailable',
    start: new Date(2015, 2, 30, 19, 30, 0),
    end: new Date(2015, 2, 30, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 13.2,
    title: 'Unavailable',
    start: new Date(2015, 3, 6, 19, 30, 0),
    end: new Date(2015, 3, 6, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 13.3,
    title: 'Unavailable',
    start: new Date(2015, 3, 13, 19, 30, 0),
    end: new Date(2015, 3, 13, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 13.4,
    title: 'Unavailable',
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 20, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 13.5,
    title: 'Unavailable',
    start: new Date(2015, 3, 27, 19, 30, 0),
    end: new Date(2015, 3, 27, 19, 30, 0),
    unavailable: true,
  },
  {
    id: 13,
    title: '$420 Jericho, Angel, Charlie',
    start: new Date(2015, 3, 21, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: '$164 Boss, Dolce',
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 48)),
  },
  {
    id: 15,
    title: '$65 Cooper',
    start: now,
    end: new Date(new Date().setHours(new Date().getHours() + 24)),
  },
  {
    id: 16,
    title: '$24 Minnie',
    start: new Date(2015, 3, 14, 15, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 17,
    title: '$32 Buttercup',
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 18,
    title: '$32 Princess',
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 17, 30, 0),
  },
  {
    id: 19,
    title: '$24 Mouse',
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 20, 30, 0),
  },
  {
    id: 20,
    title: '$55 Rain',
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 21,
    title: '$180 Waffles, Pancake',
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
]
